import { defineStore } from 'pinia';
import { get, find } from 'src/api/Page';
import { PageDto } from 'src/api/types';
import { filterIsActive } from 'src/utils/fetch';

export interface PageWithChildrenDto extends PageDto {
  children?: PageWithChildrenDto[];
}

interface LayoutStoreState {
  layout: any;
  loading: boolean;
}

export const useLayoutStore = defineStore('layout', {
  state: (): LayoutStoreState => ({
    layout: {},
    loading: false,
  }),
  actions: {
    async fetch(params: { id?: string; url?: string }) {
      this.loading = true;
      try {
        const { data } = params.id
          ? await get(params.id)
          : await find(params.url ?? '/');

        filterIsActive(data);

        this.layout = data;
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    },
  },
});
