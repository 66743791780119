import script from "./SinovatioFooter.vue?vue&type=script&setup=true&lang=ts"
export * from "./SinovatioFooter.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QFooter from 'quasar/src/components/footer/QFooter.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../node_modules/.pnpm/@quasar+app-webpack@3.11.3__19fadae530b491e33818dbf1a8400ef1/node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QFooter,QBtn,QPopupProxy,QBanner,QSeparator});
